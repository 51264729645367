export const environment = {
  production: true,
  sentry: 'https://507dc7373b2d49279d5366aff6d6db3d@sentry.io/1819852',
  version: '0.16.51',
  orderApiUrl: 'https://api.order.mlink.ortho-team.ch',
  authenticationApi: 'https://auth.mlink.ortho-team.ch',
  wunderWebUrl: 'https://client.mlink.ortho-team.ch',
  orderWebUrl: 'https://order.mlink.ortho-team.ch',
  productNumberSuffix: '',
  auth: {
    clientId: 'ortho-client',
    clientSecret: '0AB0765C-9C08-459D-B536-ADE0105B3D43',
  },
  appDistribution: {
    updateEnabled: true,
    appName: 'Ortho-Order',
    env: 'prod',
    url: 'https://apps.mlink.ortho-team.ch',
  },
  externalLinks: {
    feedback: 'https://forms.monday.com/forms/a12be93d115ab225643943f405d8e3d6',
    newSalesProduct:
      'https://forms.monday.com/forms/58da962433f0e031d4d8fede55394599?r=use1',
  },
  mlink: {
    url: 'https://api.mlink.ortho-team.ch',
    assistantId: 'jakob'
  },
  aiEnabled: false,
  keycloak: {
    url: 'https://auth.cloud.triarc-labs.com/auth',
    realm: 'ortho-team',
    clientId: 'ortho-team',
    bearerExcludedUrls: [],
    scope: 'openid profile offline_access',
  },
};
